@import './scss/fonts/font.css';

* {
  outline: none;
}
$primary-color: white;

html {
  scroll-behavior: smooth;
}

button {
  font-weight: 900;
  font-family: Helvetica-Bold;
}

.mainContainer {
  .normal {
    text-decoration: none;
    color: black;
    cursor: pointer;
  }

  .midNew {
    padding-top: 20px;
    margin: auto;
    color: black;
    font-size: 18px;
    max-width: 1300px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-end;

    .image {
      img {
        max-width: 200px;
        width: 100%;
      }
    }

    .text {
      padding: 20px;
      margin-left: 20px;
      width: 100%;
      max-width: 600px;
      color: black;
    }
  }

  .midNew {
    @media screen and (max-width:720px) {
      flex-direction: column-reverse;
      align-items: center;

      .text {
        box-sizing: border-box;
        font-size: 15px;
        margin: 2px;
      }

      .image {
        margin: auto;
        max-width: 150px;
      }
    }
  }
  width: 100vw;
  background-color: #ffff;
  font-family: 'Helvetica-Light';

  .bold {
    font-family: 'Helvetica-Bold';
    font-weight: bold;
  }

  .header {
    padding: 10px;
    box-sizing: border-box;
    height: 70px;
    box-shadow: 0 1px 10px rgba(black,.2);
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    top: -1px;
    background: white;
    z-index: 99999;

    .modelContainer {
      margin: auto;
      width: 95%;
      max-width: 1000px;
      height: 400px;
      border-radius: 10px;
      display: flex;
      overflow: hidden;
      position: fixed;
      top: 70px;
      bottom: auto;
      overflow-x: hidden;
      overflow-y: auto;


      .modalshade {
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.2);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        display: block;
      }

      .right-side {
        flex: 1;
        background-color: white;

        .lol {
          @media screen and (max-width:720px) {
            padding: 10px;
          }
          margin: 30px;
        }

        p {
          color: black;
          margin-bottom: 3px;
        }

        .row {
          display: flex;
          justify-content: flex-start;

          input {
            width: 100%;
            display: block;
            padding: 5px;
            height: calc(1.5em + .75rem + 2px);
            font-size: 1rem;
            background-color: #fff;
            border: 1px solid #ced4da;
            border-radius: 4px;
            box-sizing: border-box;
          }

          .name {
            width: 50%;
          }

          .email {
            width: 50%;
            padding-left: 5px;
          }
        }

        .message {
          margin-bottom: 10px;

          textarea {
            box-sizing: border-box;
            height: 100px;
            width: 100%;
          }
        }

        button {
          width: 100%;
          padding: 10px;
          margin-top: 10px;
          background-color: #FFB904;
          color: white;
          border: none;
          border-radius: 8px;
        }
        @media screen and (max-width:720px) {
          flex:0;
        }
      }

      .left-side {
        width: 100%;
        max-width: 300px;
        padding: 10px;
        background-color: #428397;
        h2 {
          color: #ffff;
          margin-left: 10px;
        }

        .info {
          margin-left: 10px;
          margin-bottom: 25px;

          span {
            color: #FFB904;
          }

          p {
            margin-top: 0;
            color: #ffff;
          }
        }
        @media screen and (max-width:720px) {
          display: none;
        }

      }
      @media screen and (max-width:720px) {
        flex-direction: column-reverse;
        justify-content: flex-end;
        overflow: visible;
        height: 100%;
        flex: 0;
      }
    }

    .nav {
      width: 500px;
      display: flex;
      justify-content: space-between;

      .nav-item.ul {
        color: #428397;
        display: flex;
        align-items: center;

        .li {
          color: #428397;
          text-decoration: none;
          cursor: pointer;
        }
      }
      @media screen and (max-width:720px) {
        display: none;
      }
    }

    .sign {
      display: flex;
      align-items: center;
      list-style: none;

      .li {
        color: $primary-color;
        text-decoration: none;
      }

      button {
        color: $primary-color;
        background-color: #FFB904;
        border: none;
        padding: 10px;
        width: 100px;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }

  .bodySection {
    padding-top: 100px;
    text-align: center;
    color: black;
    margin: auto;

    h1 {
      color: #0b4a5d;
      font-weight: 500;
      padding: 10px;
    }

    .imgbg {
      background: url("./assets/bghero.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      width: 100vw;
      // height: calc( 100vw * 0.379609544468547 );
      height: calc(100vw * 0.3);
      position: relative;
      bottom: -1px;
    }

    button {
      font-size: 12px;
      color: $primary-color;
      background-color: #FFB904;
      border: none;
      padding: 15px;
      border-radius: 8px;
      cursor: pointer;
      width: 150px;
    }

    .imgage {
      padding: 10px;

      img {
        width: 100%;
      }
    }
  }

  .recommended {
    direction: ltr !important;
    h2 {
      color: #428397;
      font-size: 40px;
    }
    background: #428397;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;

    .advantages {
      display: grid;
      grid-template-columns: repeat(auto-fit,minmax(244px,1fr));
      grid-gap: 1rem;
      margin: 0 auto 6rem;
      padding: 10px;

      .card {
        border-radius: 8px;
        max-width: 1000px;
        height: 300px;
        padding: 10px;
        text-align: center;
        background-color: #ffff;
        box-shadow: 0 0 10px rgba(black,.5);

        p:first-child {
          padding: 0 10px;
          color: black;
        }

        p:nth-Child(2) {
          color: black;
          padding: 0 6px;
        }

        .fa {
          font-size: 100px;
          color: #FFB904;
          height: 150px;
        }
      }
    }
  }

  .tableSection {
    background: #0b4a5d;
    margin: auto;
    text-align: center;
    padding: 20px;

    .toggleSwitch {
      width: 200px;
      margin: auto;
      align-items: center;
      justify-content: space-between;
      display: flex;
    }

    .heading {
      color: #ffff;
      padding: 10px;

      i {
        font-size: 30px;
        color: #FFB904;
        cursor: pointer;
      }
    }

    .table {
      margin-bottom: 100px;
    }

    .table {
      @media screen and (max-width:720px) {
        width: auto;
        overflow-y: scroll;
      }
    }

    .tableRow {
      margin: auto;
      max-width: 1000px;
      min-width: 800px;
      display: flex;
      justify-content: space-between;

      button {
        background-color: inherit;
        color: #097896;
        border: none;
        font-size: medium;
        cursor: pointer;
        padding: 7px;
      }

      button:hover {
        outline: #097896;
        text-decoration: underline;
        transition: ease;
      }

      .col {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        background-color: white;
        color: black;
        font-size: 16px;
        padding: 8px;
        width: 100px;

        i {
          color: #097896;
          font-size: 15px;
        }

        .coins {
          margin-top: 0;
          color: #097896;
        }
      }

      .col.col1 {
        background-color: #0b4a5d;
        color: white;

        .inlarge {
          background: red;
        }
      }

      .col.col2 {
        color: black;

        .inlarge {
          font-size: 55px;
        }
      }

      .col.col3 {
        color: black;

        .inlarge {
          font-size: 55px;
        }
      }

      .col.col4 {
        color: black;

        .inlarge {
          font-size: 55px;
        }
      }

      .col.col5 {
        background-color: #FFB904;
        color: white;

        i {
          color: white;
        }

        .call {
          border: 1px solid white;
          border-radius: 5px;
          background-color: inherit;
          color: white;
          padding: 9px;
          cursor: pointer;
        }

        .call:hover {
          background-color: white;
          color: #FFB904;
          transition: 1s;
          text-decoration: none;
        }
      }
    }
  }

  .footer {
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    margin: auto;
    width: 100%;
    background: #F5F5F5;
    padding: 10px;
    @media screen and (max-width:650px) {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .footer-logo {
      padding-top: 20px;
    }

    .footerHeading {
      color: black;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 15px;

      span {
        margin-bottom: 5px;
      }
    }
  }
}

.arabic {
  direction: rtl;
  font-family: 'Droid-regular';

  button {
    font-family: 'Droid-regular';
    font-weight: 900;
  }

  .midNew {
    .text {
      h3 {
        font-weight: 100;
      }
    }
  }

  .header {
    .logo {
      padding-top: 10px;
    }
  }

  .advantages {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(244px,1fr));
    grid-gap: 1rem;
    margin: 0 auto 6rem;
    padding: 10px;

    .card {
      p {
        font-size: 14px;
        margin: 0;
        margin-top: 3px; 
      }

      .fa {
        font-size: 70px;
        height: 0 !important;
      }
    }
  }

  .bold {
    font-family: 'Droid-regular';
    font-weight: bold;
  }

  .tableSection {
    .tableRow {
      .col {
        font-size: 14px;
      }
    }
  }
}

.arabic-font {
  font-family: 'Droid-regular' !important;
}

.english-font {
  font-family: 'Helvetica-Light' !important;
}
