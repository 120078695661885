@font-face {
    font-family: 'Droid-bold';
    src: url('DroidArabicKufi-Bold.eot');
    src: url('DroidArabicKufi-Bold.eot?#iefix') format('embedded-opentype'),
        url('DroidArabicKufi-Bold.woff2') format('woff2'),
        url('DroidArabicKufi-Bold.woff') format('woff'),
        url('DroidArabicKufi-Bold.ttf') format('truetype'),
        url('DroidArabicKufi-Bold.svg#DroidArabicKufi-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Droid-regular';
    src: url('DroidArabicKufi.eot');
    src: url('DroidArabicKufi.eot?#iefix') format('embedded-opentype'),
        url('DroidArabicKufi.woff2') format('woff2'),
        url('DroidArabicKufi.woff') format('woff'),
        url('DroidArabicKufi.ttf') format('truetype'),
        url('DroidArabicKufi.svg#DroidArabicKufi') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Helvetica-Bold';
  src: url('HelveticaNeue-Bold.eot');
  src: url('HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
  url('HelveticaNeue-Bold.woff2') format('woff2'),
  url('HelveticaNeue-Bold.woff') format('woff'),
  url('HelveticaNeue-Bold.ttf') format('truetype'),
  url('HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Helvetica-Light';
    src: url('HelveticaNeue-Light.eot');
    src: url('HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
        url('HelveticaNeue-Light.woff2') format('woff2'),
        url('HelveticaNeue-Light.woff') format('woff'),
        url('HelveticaNeue-Light.ttf') format('truetype'),
        url('HelveticaNeue-Light.svg#HelveticaNeue-Light') format('svg');
    font-weight: bold;
    font-style: normal;
}
